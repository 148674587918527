import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 5; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/31-oct-2/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function Dusshera2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Dussehra Celebration</h1>

        <p>
          Dussehra celebrates the victory of good over evil.
          <br />
          <br />
          The Pre-Primary and Primary Departments of Chinmaya Vidyalaya, New
          Delhi, celebrated the festival of Dussehra on 20th October 2023 by
          engaging the learners in some interesting activities. The little
          learners of Pre-Primary learned about the qualities of Lord Rama
          through a video presentation and enjoyed coloring the picture of Lord
          Rama. The learners of Primary classes enjoyed making Ravana using
          paper cups, sticks, and origami. They also made Dussehra cards and
          beautifully mentioned the message of the victory of good over evil
          inside them. The activities enlightened them about the significance of
          the festival.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
